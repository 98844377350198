import { BaseElement } from '../../01-base/BaseElement';
import lozad from 'lozad';

class LazySrc extends BaseElement {
    hooks() {
        return {
            connected: () => {
                this._element = this.firstElementChild;
                this.render();
                const observer = lozad(this._element, {
                    rootMargin: this.rootMargin,
                    threshold: this.threshold,
                    loaded: target => {
                        const img = target.querySelector('img');
                        if (img) img.classList.add('img');
                    },
                });
                observer.observe();
            },
        };
    }

    properties() {
        return {
            rootMargin: '48px',
            threshold: 0,
            // from: 'data-src',
            // to: 'src',
        };
    }
}

customElements.define('lazy-src', LazySrc);
